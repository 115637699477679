<template>
  <div>
    <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
        <b-form @submit.prevent="validate().then(save)">
          <b-col lg="6" md="8" sm="10">
            <base-input
              type="text"
              label="Template name"
              v-model="name"
              rules="required"
              name="Template name"
              :showErrors="false"
              :showErrorBorder="false"
              class="template-input"
            >
            </base-input>
          </b-col>
          <b-col md="10">
            <label class="form-control-label">Message</label>
            <div class="d-flex mb-2">
              <base-button
              v-for="(shortcode, index) in shortcodes"
              :key="`shortcode_${index}`"
              type="outline-primary"
              size="sm"
              @click="addShortcode(shortcode)"
              ><i class="ni ni-fat-add"></i>{{shortcode}}</base-button>
            </div>
            <app-textarea
              v-model="message"
              rules="required"
              ref="messageBlock"
              class="template-input"
            />
            <p class="d-flex justify-content-end"
            :class="{'text-danger' : message.length > messageLimit}">{{messageInstruction}}</p>
            <div class="d-flex justify-content-end">
              <button class="btn btn-outline-primary" @click.prevent="cancelingEditing">
                Cancel
              </button>
              <button class="btn btn-primary" :disabled="invalid || !canSave">
                Save
              </button>
            </div>
          </b-col>
        </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import {mapActions} from 'vuex';
import AppTextarea from '@/components/App/controls/AppTextarea.vue';
import {
  CREATE_TEMPLATE_REQUEST,
  UPDATE_TEMPLATE_REQUEST,
  CREATE_TEAM_TEMPLATE_REQUEST,
} from '@/store/storeActions';

export default {
  components: {AppTextarea},
  props: {
    template: Object,
    formBlocked: Boolean,
    canCreate: Boolean,
    isTeamTemplate: Boolean,
  },
  data: function() {
    const shortcodes = [
      'FirstName',
      'LastName',
    ];
    return {
      id: '',
      name: '',
      message: '',
      shortcodes,
      messageLimit: 400,
    };
  },
  computed: {
    canSave: function() {
      return (this.canCreate || (!this.canCreate && this.template)) && (this.message.length <= this.messageLimit);
    },
    messageInstruction: function() {
      return this.message.length+'/'+this.messageLimit;
    },
  },
  watch: {
    template: {
      handler: function(value) {
        this.initializeFields(value);
      },
      immediate: true,
    },
    name: {
      handler: function(value) {
        this.changeFormHandler('name', value);
      },
      immediate: true,
    },
    message: {
      handler: function(value) {
        this.changeFormHandler('message', value);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      CREATE_TEMPLATE_REQUEST,
      UPDATE_TEMPLATE_REQUEST,
      CREATE_TEAM_TEMPLATE_REQUEST,
    ]),
    addShortcode: function(shortcode) {
      let cursorPosition = 0;
      const {messageBlock} = this.$refs;
      if (!!messageBlock) {
        const {textarea} = messageBlock.$refs;
        textarea.focus();
        cursorPosition = textarea ? textarea.selectionStart : 0;
      }
      this.message = `${this.message.slice(0, cursorPosition)}[${shortcode}]${this.message.slice(cursorPosition)}`;
    },
    cancelingEditing: function() {
      this.initializeFields(this.template);
    },
    save: function() {
      const payload = {
        name: this.name,
        message: this.message,
      };
      if (this.isTeamTemplate) {
        !this.template && this.canCreate ? this.CREATE_TEAM_TEMPLATE_REQUEST({payload}) : this.$emit('update-template', {payload, id: this.id});
      } else {
        !this.template && this.canCreate ? this.CREATE_TEMPLATE_REQUEST({payload}) : this.$emit('update-template', {payload, id: this.id});
      }
    },
    changeFormHandler: function(field, value) {
      const blocked = (this.template && value != this.template[field]);
      const unsavedFormData = {
        id: this.id,
        name: this.name,
        message: this.message,
      };
      this.$emit('form-block-change', {blocked, unsavedFormData});
    },
    initializeFields: function(template) {
      if (!!template) {
        this.id = template.id;
        this.name = template.name;
        this.message = template.message;
      } else {
        this.id = null;
        this.name = '';
        this.message = '';
      }
    },
  },
};
</script>
<style>
  .template-input .form-control, .template-input.form-control{
      border-radius: 10px;
  }
</style>
