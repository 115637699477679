<template>
    <div class="row template-links">
        <div class="col-12 d-flex mb-5">
          <base-button
            v-if="this.isMenuShow(routeNameTemplate)"
            type="link"
            :class="activeMenu==routeNameTemplate ? 'active' : ''"
            @click="()=>{$router.push({name: routeNameTemplate})}"
          >My Templates
          </base-button>
          <base-button
            v-if="this.isMenuShow(routeNameTeamTemplate)"
            type="link"
            :class="activeMenu==routeNameTeamTemplate ? 'active' : ''"
            @click="()=>{$router.push({name: routeNameTeamTemplate})}"
          >Team Templates
          </base-button>
        </div>
    </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {isTemplateAndTeamTemplatesRouteAllow} from '@/routes/guards';
export default {
  name: 'template-header-menu',
  props: {
    activeMenu: {
      type: String,
      default: '',
      description: 'Active Menu name (Templates or Team Templates)',
    },
  },
  data: function() {
    const routeNameTemplate = 'Templates';
    const routeNameTeamTemplate = 'Team Templates';
    return {
      routeNameTemplate,
      routeNameTeamTemplate,
    };
  },
  methods: {
    isMenuShow: function(routeName) {
      return isTemplateAndTeamTemplatesRouteAllow(
        routeName, this.userType, this.accountType, this.planType, this.twilioNumberFormatted);
    },
  },
  computed: {
    ...mapGetters([
      'userType',
      'accountType',
      'planType',
      'twilioNumberFormatted',
    ]),
  },
};
</script>
<style lang="scss">
    .template-links {
      .btn-link.active {
        border-bottom: 2px solid;
        border-radius: 0;
      }
      .btn-link {
        box-shadow: none !important;
      }
    }
</style>
